<template>
  <BaseCard padding="30px">
    <div class="blocks-search">
      <font-awesome-icon icon="search" class="search-icon" />
      <FormulateInput
        type="text"
        name="search"
        placeholder="Search text"
        v-model="searctText"
      />
    </div>
    <div class="block-categories">
      <!-- Deleted slot due to the issue #629 -->
      <div
        class="block-list"
        v-for="(category, cat_idx) in categories"
        :key="cat_idx"
      >
        <Collapsible
          :title="category.title"
          :collapseId="cat_idx"
          :isOpen="searctText && showCats.includes(cat_idx) ? true : false"
          v-if="searctText && !showCats.includes(cat_idx) ? false : true"
        >
          <template v-for="(item, key, index) in blocks">
            <BlockItem
              v-bind:key="index"
              v-bind:data-component="item.component"
              v-bind:label="item.name"
              v-bind:on-dragging="onDragging"
              v-bind:icon="item.image"
              v-bind:drag-end="dragEnd"
              :click="spawnBlock"
              v-if="
                item.category === cat_idx &&
                item.component &&
                item.component !== 'Menu' &&
                !item.hidden
              "
            />
          </template>
        </Collapsible>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/Cards/BaseCard";
import BlockItem from "@/components/Blocks/BlockItem";
import Collapsible from "@/components/Collapsible/Collapsible";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

library.add(faSearch);

export default {
  name: "BlockList",
  components: {
    BaseCard,
    BlockItem,
    Collapsible,
  },
  data() {
    return {
      searctText: "",
      showCats: [],
    };
  },
  props: {
    blockList: {
      type: Object,
    },
    onDragging: {
      type: Function,
    },
    dragEnd: {
      type: Function,
    },
    categories: {
      type: Object,
    },
  },
  computed: {
    blocks() {
      let self = this;
      let raw = self.blockList;
      return Object.keys(raw)
        .filter((key) =>
          key.toUpperCase().includes(self.searctText.toUpperCase())
        )
        .reduce((obj, key) => {
          obj[key] = raw[key];
          return obj;
        }, {});
    },
  },
  methods: {
    spawnBlock() {
      // ev
      // console.log( ev.dataTransfer );
      // let dropAreas = document.querySelectorAll( '.drop-area' );
      // let dropArea = dropAreas[dropAreas.length-1];
      // console.log( dropArea );
      //console.log( data );
      //let id = this.makeId();
      //if ( parseInt( data[1] ) + 1 !== parseInt( drop_area[1] ) ) {
      //	this.updateStructure( { data, drop_area, id } );
      //}
    },
  },
  watch: {
    searctText() {
      if (this.searctText) {
        let cats = [];
        let raw = this.blockList;
        let found = Object.keys(raw).filter((key) =>
          key.toUpperCase().includes(this.searctText.toUpperCase())
        );

        found.forEach((key) => {
          let idx = cats.findIndex((cat) => cat === key);

          if (idx < 0) cats.push(this.blocks[key].category);
        });

        this.showCats = cats;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.block-list {
  overflow-x: hidden;

  ::v-deep .collapsible-content_header {
    padding: 15px 0;
    border-top: 1px solid $light_gray;

    .header-title {
      font-size: 0.875rem;
      line-height: 200%;
    }
  }

  ::v-deep .collapsible-content-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &:last-of-type {
    ::v-deep .collapsible-content_header {
      padding-bottom: 0;
      border-bottom: 0;

      &.not-collapsed {
        padding-bottom: 15px;
      }
    }
  }
}
.blocks-search {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    left: 50px;
    width: 1px;
    height: calc(100% - 14px);
    top: 50%;
    transform: translateY(-50%);
    background: $light_gray;
  }

  .search-icon {
    position: absolute;
    left: 0;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
    color: $light_text;
    width: 48px;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    transition: all 0.2s ease;

    &:hover {
      color: $gray_text_2;
    }
  }

  ::v-deep {
    .formulate-input {
      margin-bottom: 0;

      input {
        padding-right: 30px;
        border: 1px solid #f0f0f0;
        background: $white;
        border-radius: 25px;
        padding: 12px 15px 12px 65px;
        font-family: "Lato";
        font-weight: normal;
        font-size: 0.875rem;
        line-height: 100%;
        color: $placeholder_color;

        &::placeholder {
          color: $light_text;
          font-size: 0.875rem;
        }
      }
    }
  }
}
.block-categories {
  margin-top: 15px;
  height: calc(100vh - 320px);
  overflow-y: auto;
  margin-right: -15px;
  padding-right: 15px;

  &::-webkit-scrollbar {
    width: $scrollbar_width;
    height: $scrollbar_height;
  }
  &::-webkit-scrollbar-track {
    background: $light_gray;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $focus_color;
  }
}
</style>
